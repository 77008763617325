@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

.App {
  text-align: center;
}

.p-password {
  width: 100% !important;
}

.p-password input {
  width: 100% !important;
  box-sizing: border-box; /* Include padding and border within the width */
}

/* Apply to the floating label container as well */
.p-float-label {
  width: 100% !important;
}

/* Make sure the label also doesn't affect the layout */
.p-float-label label {
  width: 100% !important;
}


