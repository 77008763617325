
.p-progress-spinner-circle {
    stroke: blue !important;
  }
  
#weekdaystart_panel,
#weekdayend_panel,
#weekendend_panel,
#weekendstart_panel
{
  width: 10% !important;
  background-color: #CFDFFF !important;
}
